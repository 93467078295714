.mainbgcch{
    /* background:linear-gradient(rgb(248, 207, 207),rgb(199, 255, 255)); */
    background:linear-gradient(rgba(119, 131, 119, 0.637),rgb(213, 243, 213));

}






.latest_card_box_add{
    width: 100%;
    display: flex;
   align-items: center;
   justify-content: center;
}


.admssionleft{
width: 100%;
color: white;
}

.admssionleft .card_img11{
width: 100%;
height: 300px;
}

.admssionleft .card_img11 img{
    width: 100%;
    height: 100%;
    }


.mnc{
    text-decoration: none;
}


.admssionleft .title{
    margin-top: 50px;
}
.admssionleft .title p{
    color: black;
    font-size: 20px;
}


.accdright{
width: 40%;
color: rgb(14, 13, 13);
display: flex;
justify-content:space-between;
flex-direction: column;
align-items: center;

}
.accdright .card-image1{
    height: 300px;
    width: 80%;
    

}
.accdright .card-image1 img{
    height: 100%;
    width: 100%;
    margin: 20px 0px;
}

.admssionleft h5{
    color: black;
}
.admssionleft h6{
    color: black;
}


.admcon{
background-color: rgb(29,71,118);
color: white;
font-size: 1.2vmax;
}

@media screen and (max-width:768px){
    .latest_card_box_add{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        
    }
    .admssionleft{
        width: 100%;
        }
        .accdright{
            width: 100%;
            color: white;
            }
            .accdright .card-image1{
                width: 100%;
                height: auto;
                
                
            
            }
            .accdright .card-image1 img{
                text-align: center;
               width: 100%;
                height: 300px;
                margin: 10px 0px;

            }
            
            .admssionleft .title p{
                color: black;
                font-size: 18px;
                word-wrap: break-word;
            }
            .table{
                font-size:15px ;
            }
            .admssionleft h5{
                font-size: 15px;
                word-wrap: break-word;
            }
            
                 
}


@media screen and (max-width:500px){
    .latest_card_box_add{
        width: 100%;
        display: flex;
        flex-direction: column;
        
    }
    .admssionleft{
        width: 100%;
        }
        .accdright{
            width: 100%;
            color: white;
            }
            .accdright .card-image1{
                width: 100%;
                height: auto;
                
                
            
            }
            .accdright .card-image1 img{
                text-align: center;
                height: 250px;
            }
            
            .admssionleft .title p{
                color: black;
                font-size: 15px;
                word-wrap: break-word;
            }
            .table{
                font-size:14px ;
            }
            
                 
}
@media screen and (max-width:300px){

    .accdright .card-image1 img{
        text-align: center;
        height: 220px;
    }
    .table{
        font-size:12px ;
    }
    
}






.latest_card_box_add{
    width: 100%;
    display: flex;
}


.admssionleft{
width: 100%;
/* height: 500px; */
/* background-color:#1a4774; */
color: white;
}

.admssionleft .card_img11{
width: 100%;
height: 300px;
}

.admssionleft .card_img11 img{
    width: 100%;
    height: 100%;
    }


.mnc{
    text-decoration: none;
}


.admssionleft .title{
    margin-top: 50px;
}
.admssionleft .title p{
    color: black;
    font-size: 20px;
}


.accdright{
width: 40%;
color: rgb(14, 13, 13);
display: flex;
justify-content:space-between;
flex-direction: column;
align-items: center;

}
.accdright .card-image1{
    height: 300px;
    width: 80%;
    

}
.accdright .card-image1 img{
    height: 100%;
    width: 100%;
    margin: 20px 0px;
}

.admssionleft h5{
    color: black;
}
.admssionleft h6{
    color: black;
}


.admcon{
background-color: rgb(29,71,118);
color: white;
font-size: 1.2vmax;
}

@media screen and (max-width:768px){
    .latest_card_box_add{
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        
    }
    .admssionleft{
        width: 100%;
        }
        .accdright{
            width: 100%;
            color: white;
            }
            .accdright .card-image1{
                width: 100%;
                height: auto;
                
                
            
            }
            .accdright .card-image1 img{
                text-align: center;
               width: 100%;
                height: 300px;
                margin: 10px 0px;

            }
            
            .admssionleft .title p{
                color: black;
                font-size: 18px;
                word-wrap: break-word;
            }
            .table{
                font-size:15px ;
            }
            .admssionleft h5{
                font-size: 15px;
                word-wrap: break-word;
            }
            
                 
}


@media screen and (max-width:500px){
    .latest_card_box_add{
        width: 100%;
        display: flex;
        flex-direction: column;
        
    }
    .admssionleft{
        width: 100%;
        }
        .accdright{
            width: 100%;
            color: white;
            }
            .accdright .card-image1{
                width: 100%;
                height: auto;
                
                
            
            }
            .accdright .card-image1 img{
                text-align: center;
                height: 250px;
            }
            
            .admssionleft .title p{
                color: black;
                font-size: 15px;
                word-wrap: break-word;
            }
            .table{
                font-size:14px ;
            }
            
                 
}
@media screen and (max-width:300px){

    .accdright .card-image1 img{
        text-align: center;
        height: 220px;
    }
    .table{
        font-size:12px ;
    }
    
}




.slideimage{
    width: 100%;
    height: 500px;
}


/* .carousel-item {
    height: 350px;
} */
.carousel-caption{
    color: #fafafa;
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: rgb(216, 207, 207);
    margin: 0px .5vmax;
}

.carousel-caption{
    width: 100vw;
    position: absolute;
    left: 0;
    text-align: left;
    padding-left: 8vmax;
}
.carousel-caption h1{
font-size: 3vmax;
}
.carousel-caption p{
    font-size: 1.5vmax;
    width: 70vw;
    }

    .jj{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        padding: 2vmx;
        /* border: 1px solid white; */

    }
    @media screen and (max-width:768px){
        .jj{
            height: 20px;
            width: 20px;
    
        }
        .slideimage{
            width: 100%;
            height: 300px;
        }
    }

    @media screen and (max-width:500px){
        .jj{
            height: 20px;
            width: 20px;
    
        }
        .slideimage{
            width: 100%;
            height: 250px;
        }
    }
    
    

.ourschoolboard{
    width: 50%;
    text-align: center;
    z-index: 999;
    padding: 20px 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* color: #1D4974; */
    color: #2B5642;

    
    background-color:#C0D1D7;

}


.princd{
    width: 100%;
    height: 250px;
    margin-top: 20px;
    margin-bottom: 20px;
    min-height: auto;
    display: flex;
    padding:30px 10px;
    /* background-color: #48534e; */
    /* box-shadow: 2px 2px 5px #48534e; */

    /* color: white; */
}

.princd .photos{
    height: 85%;
  width: 22%;
  margin: 0px 20px;
  box-shadow: 2px 2px 5px #48534e;

}

.princd .director_mess{
  width: 78%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-direction: column;
  box-shadow: 2px 2px 5px #48534e;

  border: 1px solid rgb(196, 193, 193);

}
.princd .photos img{
    height: 100%;
    width: 100%;
  }

.phname{
    font-size: 13px;
    height: 20%;
    background-color: rgb(163, 177, 177);
    color: rgb(2, 2, 2);
    padding: 5px;
  box-shadow: 2px 2px 5px #48534e;

}
.latest_card_box_ad{
    width: 100%;
    display: flex;
    align-items: center;
}


.ourschoolboardeft{
color: rgb(2, 2, 2);
}
.mnc{
    text-decoration: none;
}



.ourschoolboardrighttt{
width: 40%;
color: rgb(14, 13, 13);
;


}
.bbb{
    color: black;

}

.noblebtn{
    background-color:#1D4776;
    color: wheat;
}
.noblebtn:hover{
    background-color:#1D4776;

}



.navserboard{
    flex-direction: column;
    width: 75%;
    display: flex;
}


.didyou{
    font-size: 18px;
    color: black !important;
    text-align: left;
}
@media screen and (max-width:768px){
    .latest_card_box_ad{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ourschoolboardeft{
        width: 100%;
        margin-top: 20px;
        }
        .ourschoolboardright{
            width: 100%;
            color: white;
            }
          
                
                   
                    .bbb{
                        color: black;
                        font-size: 15px;
                        
                    }
                    .admcon{
                        font-size:15px ;
                    }
                  
                    .ourschoolboard{
                        width: 80%;
                        padding: 10px 10px;
                        word-wrap: normal;
                        font-size: 13px;

                    }
                    .ourschoolboard h1{
                        font-size: 18px;
                    }
                    .titlecontact222{
                        display: none;
                    }

                    .princd{
                        height: 400px;
                        width: 100%;
                
                
                    }
                
                   
                    .princd .photos{
                        height:150px;
                     width: 150px;
                    }
                    
                    .princd .director_mess{
                      width: 100%;
                      font-size: 12px;
                      
                    
                    }
                   
                    .phname{
                        background-color: rgb(163, 177, 177);
                        color: rgb(2, 2, 2);
                        padding: 5px;
                        font-size: 10px;
                    }
                
                    
}


@media screen and (max-width:1100px){
    .princd{
        margin-top:0px;
        flex-direction: column;
        justify-content: space-between;
        align-items:center;
        height: 450px;
        width: 100%;

    }

   
    .princd .photos{
        height:150px;
     width: 190px;
      margin: 0px 20px;
    }
    
    .princd .director_mess{
      width: 100%;
      font-size: 14px;
      
    
    }

    
    .phname{
        padding: 5px;
    }

  
    
}


@media screen and (max-width:600px){
    
    .princd{
        margin-top:0px;
        margin-bottom: 20px;
        padding:30px 10px;
        flex-direction: column;
        justify-content: space-between;
        align-items:center;
        height: 420px;
        width: 100%;


    }

   
    .princd .photos{
        height:150px;
     width: 150px;
      margin: 0px 20px;
    }
    
    .princd .director_mess{
      width: 100%;
      font-size: 12px;
      
    
    }
   
    .phname{
        background-color: rgb(163, 177, 177);
        color: rgb(2, 2, 2);
        padding: 5px;
        font-size: 10px;
    }

    
    
}

@media screen and (max-width:500px){
    
    .princd{
        margin-top:0px;
        height: 400px;
        width: 100%;


    }

   
    .princd .photos{
        height:120px;
     width: 150px;
      margin: 0px 20px;
    }
    
    .princd .director_mess{
      width: 100%;
      font-size: 12px;
      
    
    }
    .princd .director_mess h5{
        width: 100%;
        font-size: 15px;
        
      
      }
   
    .phname{
        font-size: 10px;
    }

}
@media screen and (max-width:450px){
    .princd{
        height: 350px;
        width: 100%;


    }

   
    .princd .photos{
        height:90px;
     width: 120px;
      margin: 0px 20px;
    }
    
    .princd .director_mess{
      width: 100%;
      font-size: 10px;
      
    
    }
    .princd .director_mess h5{
        width: 100%;
        font-size: 12px;
        
      
      }
   
    .phname{
        font-size: 8px;
    }
}

@media screen and (max-width:400px){
    .princd{
        height: 340px;
        padding: 10px 10px;
        width: 100%;


    }

   
    .princd .photos{
        height:90px;
     width: 120px;
      margin: 0px 20px;
    }
    
    .princd .director_mess{
      width: 100%;
      font-size: 10px;
      
    
    }
    .princd .director_mess h5{
        width: 100%;
        font-size: 12px;
        
      
      }
   
    .phname{
        font-size: 8px;
    }
}


@media screen and (max-width:315px){
    .princd{
        height: 370px;
        padding: 10px 10px;
        width: 100%;


    }

   
    .princd .photos{
        height:80px;
      margin: 0px 20px;
    }
    
    .princd .director_mess{
      width: 100%;
      font-size: 10px;
      
    
    }
    .princd .director_mess h5{
        width: 100%;
        font-size: 10px;
        
      
      }
   
    .phname{
        font-size: 7px;
    }
}






.contactt{
    width: 30%;
    text-align: center;
    z-index: 999;
    padding: 20px 50px;
    top: 50%;
    left: 50%;

    transform: translate(-50%,-50%);
    background-color:#C0D1D7;
    color: #1D4974;
}

.ssss{
    margin-top: 40px;
}

.form-group{
    margin: 20px 10px;
    color: black;
}

.form-group label {
    font-size: 20px;
}
.form-group input{
    height: 50px;
}




.latest_card_box_ad{
    width: 100%;
    display: flex;
    align-items: center;
}


.contactleft{
width: 50%;
/* height: 500px; */
/* background-color:#fafbfc; */
color: white;
padding: 20px 20px;
}
.mnc{
    text-decoration: none;
    font-size: 17px;

}



.contactright{
width: 50%;
color: rgb(14, 13, 13);
position: relative;
padding: 20px 20px;


}
.bbb{
    color: black;
    font-size: 17px;

}


.admcon{
background-color: rgb(29,71,118);
color: white;
font-size: 1.2vmax;
padding: 10px 50px;

}
.map{
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
}
.map iframe{
    height:100%;
    width: 100%;

}

@media screen and (max-width:768px){
    .latest_card_box_ad{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contactleft{
        width: 100%;
        }
        .contactright{
            width: 100%;
            color: white;
            }
          
                
                   
                    .bbb{
                        color: black;
                        font-size: 13px;
                        
                    }
                    .mnc{
                        font-size: 13px;
                    }
                    .admcon{
                        font-size:15px ;
                        padding: 5px 20px;
                    }
                    .form-group label {
                        font-size: 15px;
                    }
                    .form-group input {
                        height: 40px;
                    }
                    .contactt{
                        width: 80%;
                        padding: 10px 10px;
                        word-wrap: normal;
                        align-items: center;
                        font-size: 15px;
                        
                    }
                    .contactt h1{
                        font-size: 18px;
                    }
                    .ssss{
                        color: black;
                        margin-top: 10px;
                        font-size: 18px;

                    }
                    hr{
                        color: black;
                    }
                    .map{
                        height: 200px;
                        margin-bottom: 10px;
                    }
}






.contactt{
    width: 30%;
    text-align: center;
    z-index: 999;
    padding: 20px 50px;
    top: 50%;
    left: 50%;

    transform: translate(-50%,-50%);
    background-color:#C0D1D7;
    color: #1D4974;
}

.ssss{
    margin-top: 40px;
}

.form-group{
    margin: 20px 10px;
    color: black;
}

.form-group label {
    font-size: 20px;
}
.form-group input{
    height: 50px;
}




.latest_card_box_ad{
    width: 100%;
    display: flex;
    align-items: center;
}


.contactleft{
width: 50%;
/* height: 500px; */
/* background-color:#fafbfc; */
color: white;
padding: 20px 20px;
}
.mnc{
    text-decoration: none;
    font-size: 17px;

}



.contactright{
width: 50%;
color: rgb(14, 13, 13);
position: relative;
padding: 20px 20px;


}
.bbb{
    color: black;
    font-size: 17px;

}


.admcon{
background-color: rgb(29,71,118);
color: white;
font-size: 1.2vmax;
padding: 10px 50px;

}
.map{
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
}
.map iframe{
    height:100%;
    width: 100%;

}

@media screen and (max-width:768px){
    .latest_card_box_ad{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .contactleft{
        width: 100%;
        }
        .contactright{
            width: 100%;
            color: white;
            }
          
                
                   
                    .bbb{
                        color: black;
                        font-size: 13px;
                        
                    }
                    .mnc{
                        font-size: 13px;
                    }
                    .admcon{
                        font-size:15px ;
                        padding: 5px 20px;
                    }
                    .form-group label {
                        font-size: 15px;
                    }
                    .form-group input {
                        height: 40px;
                    }
                    .contactt{
                        width: 80%;
                        padding: 10px 10px;
                        word-wrap: normal;
                        align-items: center;
                        font-size: 15px;
                        
                    }
                    .contactt h1{
                        font-size: 18px;
                    }
                    .ssss{
                        color: black;
                        margin-top: 10px;
                        font-size: 18px;

                    }
                    hr{
                        color: black;
                    }
                    .map{
                        height: 200px;
                        margin-bottom: 10px;
                    }
}






*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.footermain{
    /* background-color: #1D4776; */
    background-color: #2B5642; 

    display: flex;
    align-items:center;
    justify-content:space-between;
    padding: 0;

}
.footerleft .logo {
    height: auto;
 width: 9vw;
}
.footerleft .logo img{
 height: 100%;
 width: 100%;
 /* background-color: red; */
}



.footermid{
    color: white;
    width: 33%;
   
}
.footerleft{
 width: 33%;
 display: flex;
 align-items: center;
justify-content: center;
}

.fbrightt{
    width: 33%;


}


.socialmedia1{
    width: 5vw; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
}
.socialmedia1 i{
    font-size: 2vmax;
}

/* .navright{
    width: 17vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
} */
.seacrch{
    display: flex;
    
    
}
.aa{
    width: 20vw;
    border: white;


}
.seacrch .btn{
    color: rgb(255, 255, 255);
    border-radius: 0px;
    border: none;
}
.ri-search-line{
    color: rgb(31, 29, 29);
}
.copyright{
    width: 100%;
    /* background-color: red; */

}
.copyright p{
  /* background-color: red; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
#copyr{
    color: white;
    font-size: 1.3vmax;
    
}

.footerbottm{
    display: flex;
    color: white;
    
}
.footerbottm li{
    list-style: none;
}
.footerbottm a{
    color: white;
    text-decoration: none;
    font-size: 1.5vmax;
    margin: 20px 0px;

}

.fbleft{
    padding: 2vmax;
    /* width: 15vw; */
    text-align: left;
}

.fbright{
    text-align: left;
    padding: 2vmax;

}
.fbtm{
    /* background-color: #1D4776; */
    background-color: #2B5642; 

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 15px;

}
.fbtm p{
    margin: 0;
    text-align: left;
    font-size: 1.3vmax;
}



#myfooterul{
    position: absolute;
    z-index: 9999;
    background-color: #2B5642; 
    padding: 5px;
    right: 13%;
    width: 20%;
    display: none;
    }

   ::-webkit-scrollbar {
        width: 4px;
        height: 0px;
      }

    ::-webkit-scrollbar-track {
        background-color:rgb(224, 245, 224);
      }

      ::-webkit-scrollbar-thumb {
        background-color: #112b0a;
        border-radius: 20px;
      }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #042408;
      }

#myfooterul li{
   list-style: none;
   background-color: rgb(18, 48, 18);
   margin: 1px 2px;
   text-align: center;
   padding: 1px 2px;

}
#myfooterul li a{
   text-decoration: none;
   color: white;
   font-size: 15px;
 
 }









@media screen and (max-width:768px){
    .footermain{
    flex-direction: column;
    text-align: center;
    }

    .footerbottm a{
        font-size: 15px;
    
    }
    .fbtm p{
        font-size: 15px;
    }
    .footerleft .logo {
     width: 15vw;
    }
    .footerleft{
        margin-top: 0px;
        text-align: center;
        width: 100%;

       }
       .footermid{
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        /* background-color: red; */

    }
    
    
    .fbrightt{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 18px;
    
    }


       .fbright{
        padding: 2vmax;
    
    }
    .seacrch .btn{
        color: rgb(255, 255, 255);
        border-radius: 0px;
        border: none;
    }
    .ri-search-line{
        color: rgb(31, 29, 29);
        font-size: 15px;
    }
    .copyright{
        width:100%;
        
    
    }
    #copyr{
        color: white;
        font-size: 15px;
        /* background-color: red; */

  justify-content: center;

        
    }
    .socialmedia1{
        width: 25vw; 
        text-align: center;
    }
    .socialmedia1 i{
        font-size: 25px;
    }

    .aa{
        width: 50vw;
        border: white;
    
    
    }
    
    #myfooterul{
        left: 50%;
        transform: translate(-50%,-0%);
        width: 50%;
    
    }
    #myfooterul li a{
        font-size: 13px;
      
      }
     
    
}
@media screen and (max-width:700px){
    .fbtm p{
        font-size: 14px;
    }
   

    .copyright{
        width:100%;
    
    }
}



@media screen and (max-width:550px){
   
   
    .fbtm p{
        font-size: 13px;
    }
   
  
    .copyright{
        width: 100%;
    
    }
    
}


@media screen and (max-width:400px){
   

    .fbtm p{
        font-size: 12px;
    }
   
    
}

@media screen and (max-width:380px){
   

    .fbtm p{
        font-size: 10px;
    }
   
    
}




.first{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

}

.one{
    width: 25%;
    height: 250px;
    margin: 25px;

}
.one img{
  height: 100%;
  width: 100%;
}


@media screen and (max-width:768px){
  
  .first{
    flex-direction: column;

}
  .one{
    width: 100%;
    height: 400px;
    margin: 10px 20px;

}
}




.latest_card_box_hls{
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.heading{
font-size: 2.1vmax;
padding: 10px 10vmax;
color: #1D4776;
font-weight: bold;
}

.hlsleft{
width: 22%;

}

.hlsleft .title p{
font-size: 1.2vmax;
}
.hlsmid{
width: 56%;
}

.hlsmid p{
font-size: 1.2vmax;
}

.hlsright{
width: 22%;
}

.hlsright .title p{
font-size: 1.2vmax;
}

.card_imgl{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
/* background-color: red; */
}
.card_imgl img{

height:160px;
width: 160px;
}

.card_img{
    height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    }
    

.title1{
    display: flex;
    align-items: center;
width: 100%;
display: flex;
align-items: center;
justify-content: space-around;
}
.hlstbutton1{
width: 50%;
display: flex;
align-items: center;
justify-content: space-around;
}
.kophl{
    display: flex;
    flex-wrap: nowrap;
    padding: 10px 20px;
    background-color: #1D4776;

}
.kophl:hover{
    padding: 10px 20px;
    background-color: #1D4776;
    color: white;

}
.hls{
background-color: #1D4776;
color: white;
border-radius:0px;
font-size: 1.2vmax;
}

.ccc1_img{

    height: 500px;
    width: 100%;
   
    
}
.ccc1_img img{
    height: 100%;
    width: 100%;     
}
.ccc2_img{

    height: 300px;
    width: 100%;
  

}
.ccc2_img img{
    height: 100%;
    width: 100%;     
}

@media screen and (max-width:768px){
    .latest_card_box_hls{
        flex-direction: column;
    }
    .hlsleft{
        width: 100%;
        
        }
        .hlsmid{
            width: 100%;
            
            }
            .hlsright{
                width: 100%;
                
                }
                .hlstbutton1{
                    width: 80%;
                    display: flex;
                    align-items: center;
                    /* justify-content: space-around;    */
                    }
                    .hlstbutton1 a{
                        margin: 0px 10px;

                    }
                    
                    .card_imgl img{
                       
                        height:110px;
width: 110px;
                        }
                        .hls{
                            background-color: #1D4776;
                            color: white;
                            border-radius:0px;
                            font-size: 15px;
                            }
                            .hlsmid  p{
                                font-size: 15px;
                                }
                                .ccc1_img{

                                    height: 450px;
                                   
                                    
                                }
                               
                                .ccc2_img{
                                
                                    height: 280px;
                                    width: 100%;
                                  
                                
                                }
                               
                               
}



@media screen and (max-width:500px){
  
               
                   
                    .card_imgl img{
                       
                        height:100px;
width: 100px;
                        }
                        .kophl{
                            padding: 10px 18px;
                            font-size: 13px;
                        }
                        
                       
}

@media screen and (max-width:400px){
  
               
   
    .card_imgl img{
        height:90px;
        width: 90px;
        }
        .kophl{
            padding: 8px 15px;
            font-size: 12px;
        }
        .ccc1_img{

            height: 440px;
           
            
        }
       
        .ccc2_img{
        
            height: 260px;
            width: 100%;
          
        
        }
       
}





.latest_card_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heading1{
font-size: 30px;
padding: 10px 10vmax;
color: #2B5642;

font-weight: bold;
text-align: center;
}

.lnewsleft{
width: 30%;
}

.lnewsleft .title p{
font-size: 1.2vmax;
}
.lnewsmid{
width: 30%;
}

.lnewsmid .title p{
font-size: 1.2vmax;
}

.lnewsright{
width: 30%;
}

.lnewsright .title p{
font-size: 1.2vmax;
}
.card_img1{
height: 200px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
/* background-color: red; */
}
.card_img1 img{
height: 100%;
width: 100%;
}


.latestbutton{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
}

.kop{
/* background-color: #1D4776; */
background-color: #2B5642; 


color: white;
border-radius:0px;
font-size: 1.2vmax;
}
.viewbtn{
padding:10px 25px
}


@media screen and (max-width:768px){
.latest_card_box{
    flex-direction: column;
}
.lnewsleft{
    width: 100%;
}
.lnewsmid{
    width: 100%;
}

.lnewsright{
    width: 100%;
}
.lnewsleft .title p{
    font-size: 15px;
    }
    .lnewsright .title p{
        font-size: 15px;
        }

        .lnewsmid .title p{
            font-size: 15px;
            }
            .kop{
                font-size: 15px;
            }
            .heading1{
                font-size: 30px;
                text-align: center;
            }
            .ak{
                text-align: center;

            }

            
}

@media screen and (max-width:500px){

.viewbtn{
    padding:8px 15px;
    font-size: 13px;

}
}




.latest_card_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.heading1{
font-size: 30px;
padding: 10px 10vmax;
color: #2B5642;

font-weight: bold;
text-align: center;
}

.lnewsleft{
width: 30%;
}

.lnewsleft .title p{
font-size: 1.2vmax;
}
.lnewsmid{
width: 30%;
}

.lnewsmid .title p{
font-size: 1.2vmax;
}

.lnewsright{
width: 30%;
}

.lnewsright .title p{
font-size: 1.2vmax;
}
.card_img1{
height: 200px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
/* background-color: red; */
}
.card_img1 img{
height: 100%;
width: 100%;
}


.latestbutton{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
}

.kop{
/* background-color: #1D4776; */
background-color: #2B5642; 


color: white;
border-radius:0px;
font-size: 1.2vmax;
}
.viewbtn{
padding:10px 25px
}


@media screen and (max-width:768px){
.latest_card_box{
    flex-direction: column;
}
.lnewsleft{
    width: 100%;
}
.lnewsmid{
    width: 100%;
}

.lnewsright{
    width: 100%;
}
.lnewsleft .title p{
    font-size: 15px;
    }
    .lnewsright .title p{
        font-size: 15px;
        }

        .lnewsmid .title p{
            font-size: 15px;
            }
            .kop{
                font-size: 15px;
            }
            .heading1{
                font-size: 30px;
                text-align: center;
            }
            .ak{
                text-align: center;

            }

            
}

@media screen and (max-width:500px){

.viewbtn{
    padding:8px 15px;
    font-size: 13px;

}
}




.ourschool{
    width: 30%;
    text-align: center;
    z-index: 999;
    padding: 20px 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color:#C0D1D7;
    color: #2B5642;
    
}





.latest_card_box_ad{
    width: 100%;
    display: flex;
    align-items: center;
}


.ourschooleft{
color: rgb(2, 2, 2);
}
.mnc{
    text-decoration: none;
}



.ourschoolright{
width: 40%;
position: relative;
color: rgb(14, 13, 13);
/* background-color: red; */


}
.ri-search-line{
    color:white;

}
.bbb{
    color: black;

}

.noblebtn{
    /* background-color:#1D4776; */
background-color: #2B5642; 

    color: wheat;
    word-wrap: n;
}
.noblebtn:hover{
    /* background-color:#1D4776; */
background-color: #2B5642; 


}



.navser{
    flex-direction: column;
    /* width: 75%; */
    display: flex;
}


.didyou{
    font-size: 18px;
    color: black !important;
    text-align: left;
}
@media screen and (max-width:768px){
    .latest_card_box_ad{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ourschoolefttt{
        width: 100%;
        }
        .ourschoolright{
            width: 100%;
            color: white;
            height: auto;
            }
          
                .noble
                   
                    .bbb{
                        color: black;
                        font-size: 15px;
                        
                    }
                    .admcon{
                        font-size:15px ;
                    }
                  
                    .ourschool{
                        width: 50%;
                        padding: 10px 10px;
                        word-wrap: normal;
                        font-size: 12px;
                    }
                    .ourschool h1{
                        font-size: 16px;
                    }
                    .titlecontact222{
                        display: none;
                    }
                    .rightbad{
                                display: none;
                    }
}




.rightbad{
    min-height: 100vh;
}
.searchbox{
    display: flex;
    border: 1px solid rgb(170, 167, 167);
}
.searchbox button{
    background-color: #2B5642; 
    border-radius: 0px;
    color: white;

}
.searchbox button:hover{
    background-color: #2B5642; 
}
.nobaa{
    font-size: 18px;
    border: none;
    display: flex;
    flex-wrap: nowrap;
    height: 50px;
    width: auto;
    padding: 10px 2.2vmax;
    /* background-color:#1D4776; */
background-color: #2B5642; 

    color: wheat;
    border-radius: 2px;
    margin-top: 0px;
}
.nobaa:hover{
    /* background-color:#1D4776; */
background-color: #2B5642; 


}
.nobaaa{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px;
}
.nobaaa1{
   width: 100%;
   text-align: left;
   
}
.nobaaa1 a{
    text-decoration: none;
   color: black; 
   word-wrap: normal;

}
.dropdown-menu a{
    width: 100%;
    color: #194267;
    border: 1px solid rgb(146, 140, 140);
    border-radius: 2px ;
 }
 



*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Ascender Serif";
}
.navmain{
    /* background-color: #1D4776; */
    background-color: #2B5642; 
    display: flex;
    align-items:center;
    justify-content:space-around;
    padding:10px 20px;

}
.navleft {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
}
.navleft .logo {
    height: auto;
 width: 5vw;
 
}
.navleft .logo img{
 height: 100%;
 width: 100%;
 /* background-color: red; */
}
.navmid{
    color: white;
    text-align: center;
    width: 65%;
}


.socialmedia{
    width: 10vw; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
}
.socialmedia i{
    font-size: 2vmax;
}

.navright{
    /* width: 15vw; */
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    /* background-color: red; */
}
.seacrch{
    display: flex;
    
    
}
.seacrch .btn{
    color: rgb(255, 255, 255);
    border-radius: 0px;
    border: none;
    height: 30px;
    font-size: 18px;
    display: flex;
    align-items: center;
}
.aa{
    
    border: white;
    height: 30px;
    text-align: center;
}

.aa:focus{
outline: none;
}

.nextnavbar {
font-size: 1.3vmax;
color: #313C74;
}
.loginbtn{
    color: white;
    background-color: #EF4444;
}
.nextnavbar .loginbtn {
    color: white;
}
.nextnavbar .loginbtn:hover{
    background-color: #EF4444;

}

.navbar-toggler{
    border: none;
  
}
#firsth{
    font-size: 35px;
}
.navmid h1{
    font-size: 25px;
}


#myul{
    position: absolute;
    z-index: 999;
    background-color: #2B5642; 
    padding: 5px;
    right: 8%;
    width: 20%;
    display: none;

}
#myul li{
   list-style: none;
   background-color: rgb(18, 48, 18);
   margin: 2px;
   text-align: center;
   padding: 2px;

}
#myul li a{
   text-decoration: none;
   color: white;
 
 }







@media screen and (max-width:768px){
    .navmain{
    flex-direction: column;
    padding: 13px 0px 18px 0px;

    }
    .navleft {
        width: 100%;
        margin-bottom:8px;
    }
    .navmid {
        width: 100%;
    }
    .navright {
        width: 100%;
    }
    .socialmedia{
        width: 40vw; 
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
    }
    .socialmedia i{
        font-size: 30px;
    }
    .nav-link{
        font-size: 15px;
        color: #2F3A72;
        font-weight: bold;
    }
    .navtoggle{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .navleft .logo {
        height: auto;
     width: 20vw;
    }
    .navmid h1{
        font-size: 22px;
    }
    #firsth{
        font-size: 28px;
    }
    
    .aa{
       height: 30px;
       

    }
    .seacrch .btn{
        height: 30px;
        font-size: 17px;
    }
    .socialmedia i{
        font-size: 20px;
    }
    .navmid p{
       font-size: 12px;
    }
    .socialmedia{
        width: 30vw; 
    }
    #myul{
        position: absolute;
        padding: 5px;
        left: 50%;
        transform: translate(-50%,-0%);
        width: 50%; 
        display: none;
    
    }
}




@media screen and (max-width:500px){
    .navmain{
    flex-direction: column;
    padding: 13px 0px 18px 0px;
    }
    .navleft {
        width: 100%;
        margin-bottom:8px;
    }
    .navmid {
        width: 100%;
    }
    .navright {
        width: 100%;
    }
    .socialmedia{
        width: 40vw; 
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
    }
    .socialmedia i{
        font-size: 30px;
    }
    .nav-link{
        font-size: 15px;
        color: #2F3A72;
        font-weight: bold;
    }
    .navtoggle{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .navleft .logo {
        height: auto;
     width: 20vw;
    }
    .navmid h1{
        font-size: 15px;
    }
    #firsth{
        font-size: 23px;
    }
    
    .aa{
       height: 30px;

    }
    .seacrch .btn{
        height: 30px;
        font-size: 15px;
    }
    .socialmedia i{
        font-size: 20px;
    }
    .navmid p{
       font-size: 12px;
    }
    .socialmedia{
        width: 30vw; 
    }
}


.latest_card_box_ad{
    width: 100%;
    display: flex;
    align-items: center;
}


.ladmleft{
width: 50%;
height: 500px;
/* background-color:#2E63B1; */
background-color: #2B5642; 

color: white;
}

.ladmleft .title p{
font-size: 1.2vmax;
}


.ladmright{
width: 50%;
height: 500px;
/* background-color: #1D4776; */
background-color: #143325; 

color: white;

}

.ladmright .title p{
font-size: 1.2vmax;
}
.card_img{
height: 200px;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
}



.readbutton{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
}



.adm{
background-color: #F59902;
color: white;
border-radius:0px;
font-size: 1.2vmax;
}


@media screen and (max-width:768px){
    .latest_card_box_ad{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ladmleft{
        width: 100%;
        height: 500px;
        }
        .ladmright{
            width: 100%;
            height: 500px;
            color: white;
            }
            .ladmright .title p{
                font-size: 15px;
                }
                .ladmleft .title p{
                    font-size: 15px;
                    }
                    .adm{
                        font-size: 15px;
                    }
}


@media screen and (max-width:400px){
    .adm{
        padding: 5px 10px;
        }

        .ladmleft{
            width: 100%;
            height: 450px;
            }
            .ladmright{
                width: 100%;
                height: 450px;
                color: white;
                }
        
}

@media screen and (max-width:600px){
    .adm{
        padding: 5px 10px;
        }

        .ladmleft{
            width: 100%;
            height: 400px;
            }
            .ladmright{
                width: 100%;
                height: 400px;
                color: white;
                }
        
}


        @media screen and (max-width:400px){
            .adm{
                padding: 5px 10px;
                }
        
                .ladmleft{
                    width: 100%;
                    height: 500px;
                    }
                    .ladmright{
                        width: 100%;
                        height: 500px;
                        color: white;
                        }
                
        }



        @media screen and (max-width:350px){
    
            .title .lnns{
                font-size: 20px;
            }
            .title p{
                font-size: 8px;
            }
            .ladmleft{
                width: 100%;
                height: 550px;  
                }
                .ladmright{
                    width: 100%;
                    height: 550px;
                    color: white;
                    }
}




.liscontainer{
    text-align: center;
    margin-bottom: 40px;
}
.facilitycontent{
    font-size: 20px;
    color: #090909;
}
.liscamplogo{
    height: 500px;
    width: 100%;
}
.liscamplogo img{
    height: 100%;
    width: 100%;
}

.linkwrap{
  word-wrap: break-word; 
  font-size: 23px;
}


.facilitCard{
    height: 200px;
    width: 100%;
    display: flex;
    margin-top: 20px;
    background-color: rgba(197, 188, 245, 0.726);

}
.facilitCard:nth-child(2){
   
    background-color: rgba(180, 255, 173, 0.726);

}

.facilitCard:nth-child(3){
   
    background-color: rgba(255, 196, 173, 0.726);

}

.facilitCard:nth-child(4){
   
    background-color: rgba(221, 160, 245, 0.726);

}



.facilitCard:nth-child(5){
   
    background-color: rgba(243, 162, 232, 0.726);

}
.facilitCard:nth-child(6){
   
    background-color: rgba(173, 255, 177, 0.726);

}

.facilitCard:nth-child(7){
   
    background-color: rgba(173, 252, 255, 0.726);

}
.facilitCard:nth-child(8){
   
    background-color: rgba(252, 191, 244, 0.726);

}
.facilimages{
    width: 20%;
    height: 100%;
}

.facilimages img{
    width: 100%;
    height: 100%;
}
.facontent{
    width: 80%;
    height: 100%;
    /* background-color: red; */
    text-align: left;
    padding: 20px 30px;
}
.facontent .factitle{
    color: black;
    font-weight: bold;
}
.facontent p{
    color: black;
    font-size: 17px;
    margin-top: 10px;
}









@media screen and (max-width:768px){
    .liscamplogo{
        height: 300px;
        width: 100%;
    }
    .facilitycontent{
        font-size: 16px;

    }
    .linkwrap{
        word-wrap: break-word; 
        font-size: 19px;
      }

      .facilitCard{
        height: auto;
        width: 100%;        
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: rgba(197, 188, 245, 0.726);
    
    }
    .liscamplogo{
        display: none;
    }

    .facilimages{
        width: 100%;
        height:400px;
    }
    
    
    .facontent{
    text-align: center;
    padding: 20px;
    /* background-color: red; */
}
    .facontent .factitle{
        color: black;
        font-weight: bold;
        font-size: 25px;
    }
    .facontent p{
        color: black;
        font-size: 18px;
        margin-top: 10px;
    }
}

@media screen and (max-width:500px){
    .facilimages{
        width: 100%;
        height:350px;
    }
    .facontent .factitle{
        font-size: 23px;
    }
}


@media screen and (max-width:414px){
    .facilimages{
        width: 100%;
        height:280px;
    }
    .facontent .factitle{
        font-size: 22px;
    }
}
     

@media screen and (max-width:380px){
    .facilimages{
        width: 100%;
        height:250px;
    }
    .facontent .factitle{
        color: black;
        font-weight: bold;
        font-size: 20px;
    }
}




.headmistressname{
    height: auto;
}
